import React from "react";
import { getPagePath } from "@nanostores/router";
import { $router } from "../backbone/router.js";

export function AddButton() {
  return (
    <a className={"button"} href={getPagePath($router, "domainsNew")}>
      Add
    </a>
  );
}
