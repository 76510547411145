import React from "react";

export function ChainIdBadge(props: { chainId: number }) {
  switch (props.chainId) {
    case 5:
      return <span className={"bg-neutral-50 text-neutral-400 p-2 rounded-lg text-xs"}>Goerli Testnet</span>;
    case 1:
      return <span className={"bg-green-100 p-2 rounded-lg text-xs"}>Mainnet</span>;
    default:
      return <span>Chain {props.chainId}</span>;
  }
}
