.header {
  @apply sticky top-0 w-full backdrop-blur bg-white/[0.6] transition-colors duration-500 lg:z-50 lg:border-b lg:border-slate-900/10;
  @apply max-w-full mx-auto;
  @apply border-b border-slate-900/10;
  /*@apply dark:border-slate-50/[0.06] dark:bg-slate-900/75;*/
  /*@apply dark:border-slate-300/10;*/
}

.inner {
  @apply relative flex justify-between;
  @apply items-baseline;
}

.dashstar {
  font-family: "Victor Mono", monospace;
  @apply font-bold;
}

.asterisk {
  font-size: larger;
}

.inner > * {
  @apply py-4 lg:px-8 mx-4 lg:mx-0;
  @apply flex-grow;
  @apply text-center;
}

.inner > :first-child {
  @apply text-left flex-grow-0;
  @apply w-1/4;
}

.inner > :last-child {
  @apply text-right flex-grow-0;
  @apply w-1/4;
}
