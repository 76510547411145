import { z } from "zod";
import { initContract, type ServerInferResponses } from "@ts-rest/core";

const ChainIdSchema = z.string().regex(/^\d+$/, "ChainId must be a number").transform(Number);

export const EntryKindSchema = z.union([z.literal("addr"), z.literal("text"), z.literal("contenthash")], {
  required_error: "kind must be present",
});

export const EntrySchema = z.object({
  kind: EntryKindSchema,
  key: z.string({ required_error: "key must be present" }),
  value: z.string({ required_error: "value must be present" }),
});
export type EntrySchema = z.TypeOf<typeof EntrySchema>;

const EntryUpdateSchema = z.object({
  value: z.string({ required_error: "value must be present" }),
});

const EntriesSchema = z.object({
  entries: z.array(EntrySchema),
});

export const ResolverSchema = z.object({
  domain: z.string({ required_error: "domain must be present" }),
  chainId: z.number({ required_error: "chainId must be present" }),
  ttl: z.number({ required_error: "ttl must be present" }),
});
export type ResolverSchema = z.TypeOf<typeof ResolverSchema>;

export const FullResolverSchema = ResolverSchema.extend({
  entries: z.record(EntrySchema),
});
export type FullResolverSchema = z.TypeOf<typeof FullResolverSchema>;

export const ResolverInputSchema = ResolverSchema.extend({
  ttl: z.number().default(300),
});
export type ResolverInputSchema = z.TypeOf<typeof ResolverInputSchema>;

export const ResolverUpdateSchema = FullResolverSchema.pick({ ttl: true, entries: true });
export type ResolverUpdateSchema = z.TypeOf<typeof ResolverUpdateSchema>;

const ResolversSchema = z.object({
  resolvers: z.array(FullResolverSchema),
});

const ErrorSchema = z.object({
  message: z.string(),
});

const contract = initContract();

const entriesContract = contract.router(
  {
    index: {
      method: "GET",
      path: "/",
      pathParams: z.object({ chainId: ChainIdSchema, domain: z.string() }),
      responses: {
        404: ErrorSchema,
        200: EntriesSchema,
      },
      summary: "List entries by resolver",
    },
    create: {
      method: "POST",
      path: "/",
      pathParams: z.object({ chainId: ChainIdSchema, domain: z.string() }),
      body: EntrySchema,
      responses: {
        201: z.void(),
        404: ErrorSchema,
        409: ErrorSchema,
      },
      summary: "Create entry for resolver",
    },
    delete: {
      method: "DELETE",
      path: "/:kind/:key",
      body: z.void(),
      pathParams: z.object({
        chainId: ChainIdSchema,
        domain: z.string(),
        kind: EntryKindSchema,
        key: z.string(),
      }),
      responses: {
        204: z.void(),
        404: ErrorSchema,
      },
    },
    update: {
      method: "PUT",
      path: "/:kind/:key",
      body: EntryUpdateSchema,
      pathParams: z.object({
        chainId: ChainIdSchema,
        domain: z.string(),
        kind: EntryKindSchema,
        key: z.string(),
      }),
      responses: {
        200: EntrySchema,
        404: ErrorSchema,
      },
    },
  } as const,
  {
    pathPrefix: "/:chainId/:domain/entries",
  },
);

const resolversContract = contract.router(
  {
    entries: entriesContract,
    create: {
      method: "POST",
      path: "/",
      responses: {
        201: FullResolverSchema,
      },
      body: ResolverInputSchema,
      summary: "Create resolver",
    },
    indexAll: {
      method: "GET",
      path: "/",
      responses: {
        200: ResolversSchema,
      },
      summary: "Show all resolvers",
    },
    indexByChainId: {
      method: "GET",
      path: "/:chainId",
      pathParams: z.object({
        chainId: ChainIdSchema,
      }),
      responses: {
        200: ResolversSchema,
      },
      summary: "Show all resolvers by chain id",
    },
    read: {
      method: "GET",
      path: "/:chainId/:domain",
      pathParams: z.object({
        chainId: ChainIdSchema,
        domain: z.string(),
      }),
      responses: {
        200: FullResolverSchema,
        404: ErrorSchema,
      },
      summary: "Read resolver",
    },
    update: {
      method: "PUT",
      path: "/:chainId/:domain",
      pathParams: z.object({
        chainId: ChainIdSchema,
        domain: z.string(),
      }),
      body: ResolverUpdateSchema,
      responses: {
        200: FullResolverSchema,
      },
    },
    delete: {
      method: "DELETE",
      path: "/:chainId/:domain",
      pathParams: z.object({
        chainId: ChainIdSchema,
        domain: z.string(),
      }),
      responses: {
        204: z.void(),
      },
      body: z.void(),
      summary: "Destroy resolver",
    },
  } as const,
  { pathPrefix: "/resolvers" },
);

export const HttpSchema = contract.router({
  resolvers: resolversContract,
} as const);

export type HttpSchema = ServerInferResponses<typeof HttpSchema>;

export function entryKey(entry: Pick<EntrySchema, "kind" | "key">): string {
  return `${entry.kind}:${entry.key}`;
}

export function resolverKey(resolver: Pick<ResolverSchema, "chainId" | "domain">): string {
  return `${resolver.chainId}:${resolver.domain}`;
}
