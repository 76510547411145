import { Type, number, string, isLeft, type Context, type Either, type Errors } from "codeco";

export const urlAsString = new Type<URL, string, string>(
  "URL",
  (input: unknown): input is URL => input instanceof URL,
  (input, context) => {
    try {
      return context.success(new URL(input));
    } catch (e) {
      return context.failure(String(e));
    }
  },
  (url) => url.toString(),
);

export const numberFromString = new Type<number, number>(
  "NumberFromString",
  number.is.bind(number),
  (input: unknown, context: Context): Either<Errors, number> => {
    if (typeof input === "number") {
      return context.success(input);
    }
    const stringE = string.decode(input, context);
    if (isLeft(stringE)) return stringE;
    const result = +stringE.right;
    if (stringE.right && Number.isFinite(result)) {
      return context.success(result);
    } else {
      return context.failure();
    }
  },
  number.encode.bind(number),
);
