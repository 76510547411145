import React, {
  ChangeEvent,
  type FormEvent,
  FormEventHandler,
  MouseEventHandler,
  SyntheticEvent,
  useMemo,
  useRef,
  useState,
} from "react";
import { Layout } from "../components/layout.jsx";
import { useStore } from "@nanostores/react";
import { $resolvers, addResolverEntry, deleteResolverEntry, updateResolver } from "../backbone/resolvers-store.js";
import { getPagePath } from "@nanostores/router";
import { $router } from "../backbone/router.js";
import { entryKey, EntrySchema, type FullResolverSchema, resolverKey } from "@spaceblanc/http-schema";
import * as styles from "./domains-edit.module.css";
import { ChainIdBadge } from "../components/chain-id-badge.jsx";
import { PINATA_CLIENT } from "../backbone/environment.js";
import { formatsByCoinType, formatsByName, type IFormat } from "@ensdomains/address-encoder";
import { hex } from "@scure/base";
import { InputButtons } from "../components/input-buttons.js";
import { useShowToggle, type ShowToggle } from "../components/use-show-toggle.js";
import { contenthash } from "ens-encoding/contenthash";

type DomainsEditProps = {
  chainId: number;
  domain: string;
};

function updateResolverSync(r: FullResolverSchema): void {
  updateResolver(r).catch((error) => {
    console.error(error);
  });
}

function TextEntry(props: { entry: EntrySchema; resolver: FullResolverSchema }) {
  const key = props.entry.key;
  const kind = props.entry.kind;
  const [value, setValue] = useState(props.entry.value);

  const handleDelete = () => {
    updateResolverSync(deleteResolverEntry(props.resolver, kind, key));
  };

  const handleUndo = () => {
    setValue(props.entry.value);
  };

  const handleSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    handleSave();
  };

  const handleSave = () => {
    updateResolverSync(
      addResolverEntry(props.resolver, {
        ...props.entry,
        value: value,
      }),
    );
  };

  return (
    <form className={"mb-1 rounded bg-neutral-100 flex justify-between w-full"} onSubmit={handleSubmit}>
      <div className={"font-semibold opacity-75 font-mono w-1/4 grow-0 pl-2 py-2"}>{key}</div>
      <div className={"grow p-0 flex-initial"}>
        <input
          type={"text"}
          value={value}
          className={"w-full border-0 bg-neutral-50 "}
          onChange={(e) => setValue(e.currentTarget.value)}
        />
      </div>
      <InputButtons
        original={props.entry.value}
        current={value}
        onSave={handleSave}
        onUndo={handleUndo}
        onDelete={handleDelete}
      />
    </form>
  );
}

function NewTextEntry(props: { showToggle: ShowToggle; resolver: FullResolverSchema }) {
  if (!props.showToggle.isShown) return <></>;

  const [entry, setEntry] = useState<EntrySchema>({
    kind: "text",
    key: "",
    value: "",
  });

  const isButtonDisabled = useMemo(() => {
    if (!entry.key) return true;
    if (!entry.value) return true;

    // Duplicate keys
    const entryKeys = Object.keys(props.resolver.entries);
    return entryKeys.includes(entryKey(entry));
  }, [entry.key, entry.value, props.resolver.entries]);

  const handleSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    updateResolver(addResolverEntry(props.resolver, entry))
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        props.showToggle.hide();
      });
  };

  const handleDelete = () => {
    props.showToggle.hide();
  };

  return (
    <form className={styles.newEntryContainer} onSubmit={handleSubmit}>
      <div className={styles.newEntryInputLine}>
        <div className={"font-semibold opacity-75 font-mono w-1/4 grow-0 p-0"}>
          <input
            type={"text"}
            value={entry.key}
            className={"w-full border-0 bg-neutral-50 shadow-inner"}
            onChange={(e) => setEntry({ ...entry, key: e.currentTarget.value })}
          />
        </div>
        <div className={"grow p-0 flex-initial"}>
          <input
            type={"text"}
            value={entry.value}
            className={"w-full border-0 bg-neutral-50 border-l border-l-gray-200 shadow-inner"}
            onChange={(e) => setEntry({ ...entry, value: e.currentTarget.value })}
          />
        </div>
        <InputButtons original={1} current={1} onDelete={handleDelete} />
      </div>
      <button type={"submit"} disabled={isButtonDisabled}>
        Add
      </button>
    </form>
  );
}

function TextEntries(props: { entries: Array<EntrySchema>; resolver: FullResolverSchema }) {
  const showToggle = useShowToggle();

  const renderBody = () => {
    const textEntries = props.entries.filter((e) => e.kind === "text");
    if (textEntries.length > 0) {
      return textEntries.map((entry) => {
        return <TextEntry entry={entry} key={entryKey(entry)} resolver={props.resolver} />;
      });
    }
    if (!showToggle.isShown) {
      return (
        <p>
          No <code>text</code> records
        </p>
      );
    }
  };

  return (
    <div className={"mt-4"}>
      <h2 className={styles.kindHeader}>
        <span>Text</span> <InlineAddButton showToggle={showToggle} />
      </h2>
      <NewTextEntry showToggle={showToggle} resolver={props.resolver} />
      {renderBody()}
    </div>
  );
}

function InlineAddButton(props: { showToggle: ShowToggle }) {
  if (props.showToggle.isShown) return;
  return (
    <a className={styles.addButton} onClick={props.showToggle.show}>
      Add
    </a>
  );
}

function NewAddrEntry(props: { showToggle: ShowToggle; resolver: FullResolverSchema }) {
  if (!props.showToggle.isShown) return;
  const [value, setValue] = useState({ name: "ETH", address: "" });

  const constructEntry = (name: string, address: string): EntrySchema | null => {
    const format = formatsByName[value.name];
    try {
      const entryValue = format.decoder(value.address);
      return {
        kind: "addr",
        key: String(format.coinType),
        value: `0x${hex.encode(entryValue)}`,
      };
    } catch {
      return null;
    }
  };

  const entry = useMemo<EntrySchema | null>(
    () => constructEntry(value.name, value.address),
    [value.name, value.address],
  );

  const isButtonDisabled = useMemo(() => {
    if (!value.name) return true;
    if (!value.address) return true;
    if (!entry) return true;

    // Duplicate keys
    const entryKeys = Object.keys(props.resolver.entries);
    return entryKeys.includes(entryKey(entry));
  }, [value.name, value.address, props.resolver.entries]);

  const handleSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    if (!entry) return;
    updateResolver(addResolverEntry(props.resolver, entry))
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        props.showToggle.hide();
      });
  };

  const handleFormatChange = (e: SyntheticEvent<HTMLSelectElement, Event>) => {
    setValue({
      name: e.currentTarget.value,
      address: "",
    });
  };

  const handleAddressChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue({
      ...value,
      address: e.currentTarget.value,
    });
  };

  const handleDelete = () => {
    updateResolverSync(props.resolver);
  };

  return (
    <form className={styles.newEntryContainer} onSubmit={handleSubmit}>
      <div className={styles.newEntryInputLine}>
        <div className={"font-semibold opacity-75 font-mono w-1/4 grow-0 p-0"}>
          <select name={"currency"} value={value.name} className={styles.selectInput} onChange={handleFormatChange}>
            <option value={formatsByName["BTC"].name}>BTC</option>
            <option value={formatsByName["ETH"].name}>ETH</option>
            <option value={formatsByName["XTZ"].name}>XTZ</option>
          </select>
        </div>
        <div className={"grow p-0 flex-initial"}>
          <input
            type={"text"}
            value={value.address}
            className={"w-full border-0 bg-neutral-50 border-l border-l-gray-200 shadow-inner"}
            onChange={handleAddressChange}
          />
        </div>
        <InputButtons original={1} current={1} onDelete={handleDelete} />
      </div>
      <button type={"submit"} disabled={isButtonDisabled}>
        Add
      </button>
    </form>
  );
}

function AddrEntry(props: { entry: EntrySchema; resolver: FullResolverSchema }) {
  let name = props.entry.key;
  let address = props.entry.value;

  const handleDelete = () => {
    const key = props.entry.key;
    const kind = props.entry.kind;
    updateResolverSync(deleteResolverEntry(props.resolver, kind, key));
  };

  try {
    const coinType = Number(props.entry.key);
    const format = formatsByCoinType[coinType];
    name = format.name;
    address = format.encoder(Buffer.from(hex.decode(props.entry.value.replace(/^0x/, ""))));
  } catch {
    // Do Nothing
  }

  return (
    <div className={"mb-1 rounded bg-neutral-100 flex justify-between w-full"}>
      <div className={"font-semibold opacity-75 font-mono w-1/4 grow-0 pl-2 py-2"}>{name}</div>
      <div className={"grow p-0 flex-initial"}>
        <input type={"text"} value={address} readOnly={true} className={"w-full border-0 bg-neutral-50 "} />
      </div>
      <InputButtons original={1} current={1} onDelete={handleDelete} />
    </div>
  );
}

function AddrEntries(props: { resolver: FullResolverSchema }) {
  const showToggle = useShowToggle();
  const renderBody = () => {
    const addressEntries = Object.values(props.resolver.entries).filter((e) => e.kind === "addr");
    if (addressEntries.length > 0) {
      return addressEntries.map((entry) => {
        return <AddrEntry entry={entry} key={entryKey(entry)} resolver={props.resolver} />;
      });
    }
    if (!showToggle.isShown) {
      return (
        <p>
          No <code>addr</code> records
        </p>
      );
    }
  };

  return (
    <div className={"mt-4"}>
      <h2 className={styles.kindHeader}>
        <span>Addresses</span>
        <InlineAddButton showToggle={showToggle} />
      </h2>
      <NewAddrEntry showToggle={showToggle} resolver={props.resolver} />
      {renderBody()}
    </div>
  );
}

function NewContenthashEntry(props: { showToggle: ShowToggle; resolver: FullResolverSchema }) {
  if (!props.showToggle.isShown) return <></>;
  const [value, setValue] = useState("");

  const entry = useMemo<EntrySchema | null>(() => {
    try {
      return {
        kind: "contenthash",
        key: "contenthash",
        value: contenthash.encode(value),
      };
    } catch (e) {
      return null;
    }
  }, [value]);

  const isButtonDisabled = useMemo(() => {
    if (!entry) return true;
    if (!entry.key) return true;
    if (!entry.value) return true;

    // Duplicate keys
    const entryKeys = Object.keys(props.resolver.entries);
    return entryKeys.includes(entryKey(entry));
  }, [entry, props.resolver.entries]);

  const handleSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    if (!entry) return;
    updateResolver(addResolverEntry(props.resolver, entry))
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        props.showToggle.hide();
      });
  };

  const handleDelete = () => {
    props.showToggle.hide();
  };

  return (
    <form className={styles.newEntryContainer} onSubmit={handleSubmit}>
      <div className={styles.newEntryInputLine}>
        <div className={"grow p-0 flex-initial"}>
          <input
            type={"text"}
            value={value}
            className={"w-full border-0 bg-neutral-50 border-l border-l-gray-200 shadow-inner"}
            onChange={(e) => setValue(e.currentTarget.value)}
          />
        </div>
        <InputButtons original={1} current={1} onDelete={handleDelete} />
      </div>
      <button type={"submit"} disabled={isButtonDisabled}>
        Add
      </button>
    </form>
  );
}

// FIXME handle conversion between human and contenthash
function ContenthashEntry(props: { entry: EntrySchema; resolver: FullResolverSchema }) {
  const key = props.entry.key;
  const kind = props.entry.kind;
  const [value, setValue] = useState(contenthash.decode(props.entry.value));

  const entry = useMemo<EntrySchema | null>(() => {
    try {
      return {
        ...props.entry,
        value: contenthash.encode(value),
      };
    } catch {
      return null;
    }
  }, [props.entry, value]);

  const handleDelete = () => {
    updateResolverSync(deleteResolverEntry(props.resolver, kind, key));
  };

  const handleUndo = () => {
    setValue(contenthash.decode(props.entry.value));
  };

  const handleSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    handleSave();
  };

  const handleSave = () => {
    updateResolverSync(
      addResolverEntry(props.resolver, {
        ...props.entry,
        value: contenthash.encode(value),
      }),
    );
  };

  return (
    <form className={"mb-1 rounded bg-neutral-100 flex justify-between w-full"} onSubmit={handleSubmit}>
      <div className={"grow p-0 flex-initial"}>
        <input
          type={"text"}
          value={value}
          className={"w-full border-0 bg-neutral-50 "}
          onChange={(e) => setValue(e.currentTarget.value)}
        />
      </div>
      <InputButtons
        original={props.entry.value}
        current={entry?.value}
        onSave={handleSave}
        onUndo={handleUndo}
        onDelete={handleDelete}
      />
    </form>
  );
}

function ContenthashEntries(props: { resolver: FullResolverSchema }) {
  const showToggle = useShowToggle();
  const entries = Object.values(props.resolver.entries);
  const entry = entries.find((e) => e.kind === "contenthash");

  const renderBody = () => {
    if (entry) {
      return <ContenthashEntry entry={entry} key={entryKey(entry)} resolver={props.resolver} />;
    }
    if (!showToggle.isShown) {
      return (
        <p>
          No <code>contenthash</code> records
        </p>
      );
    }
  };

  return (
    <div className={"mt-4"}>
      <h2 className={styles.kindHeader}>
        <span>Contenthash</span> <InlineAddButton showToggle={showToggle} />
      </h2>
      <NewContenthashEntry showToggle={showToggle} resolver={props.resolver} />
      {renderBody()}
    </div>
  );
}

function DomainsEditForm(props: { resolver: FullResolverSchema }) {
  const entries = Object.values(props.resolver.entries);

  const handleAvatarUpdate = (url: string | null) => {
    if (url) {
      updateResolverSync(
        addResolverEntry(props.resolver, {
          kind: "text",
          key: "avatar",
          value: url,
        }),
      );
    } else {
      updateResolverSync(deleteResolverEntry(props.resolver, "text", "avatar"));
    }
  };

  return (
    <div className={styles.form}>
      <div className={styles.headerTitle}>
        <div className={styles.nameHeader}>
          <Avatar original={props.resolver} onURL={handleAvatarUpdate} />
          {props.resolver.domain}
        </div>
        <div>
          <ChainIdBadge chainId={props.resolver.chainId} />
        </div>
      </div>
      <TextEntries entries={entries} resolver={props.resolver} />
      <AddrEntries resolver={props.resolver} />
      <ContenthashEntries resolver={props.resolver} />
    </div>
  );
}

function Avatar(props: { original: FullResolverSchema; onURL: (url: string | null) => void }) {
  const inputRef = useRef<HTMLInputElement>(null);
  const avatarEntry = props.original.entries[entryKey({ kind: "text", key: "avatar" })];

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files;
    if (!files || !files[0]) return;
    const file = files[0];
    PINATA_CLIENT.upload(file)
      .then((r) => {
        props.onURL(`ipfs://${r.IpfsHash}`);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const handleClick = () => {
    inputRef.current?.click();
  };

  const handleDeleteClick = () => {
    props.onURL(null);
  };

  const renderBody = () => {
    if (avatarEntry) {
      const cid = avatarEntry.value.replace("ipfs://", "");
      return (
        <>
          <div className={styles.avatar}>
            <img src={PINATA_CLIENT.urlForCID(cid).href} className={styles.avatarImage} />
          </div>
          <div className={styles.avatarEditButton} onClick={handleClick} title={"Change"}>
            <div className={"m-auto"}>~</div>
          </div>
          <div className={styles.avatarDeleteButton} onClick={handleDeleteClick} title={"Delete"}>
            <div className={"m-auto"}>×</div>
          </div>
        </>
      );
    } else {
      return (
        <div className={styles.avatarPointer}>
          <div className={styles.avatarPlaceholderInnerCircle}>
            <div className={styles.avatarPlaceholderInner}>+</div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className={styles.avatarImageWrap} onClick={avatarEntry ? undefined : handleClick}>
      {renderBody()}
      <input
        type={"file"}
        multiple={false}
        accept={"image/png, image/jpeg, image/gif"}
        onChange={handleChange}
        ref={inputRef}
        className={"hidden"}
      />
    </div>
  );
}

export function DomainsEdit(props: React.PropsWithChildren<DomainsEditProps>) {
  const key = resolverKey({ chainId: props.chainId, domain: props.domain });
  const resolvers = useStore($resolvers, { keys: [key] });
  const resolver = resolvers[key];

  const renderBody = () => {
    if (resolver) {
      return <DomainsEditForm resolver={resolver} />;
    } else {
      return <div>Loading...</div>;
    }
  };

  return (
    <Layout
      center={
        <div>
          <a href={getPagePath($router, "domainsIndex")} className={"center-breadcrumb"}>
            Domains
          </a>{" "}
          <span className={"mx-2 text-neutral-300"}>∕</span> <span className={"center-breadcrumb"}>{props.domain}</span>
        </div>
      }
    >
      <div className={"max-w-screen-sm mx-auto"}>{renderBody()}</div>
    </Layout>
  );
}
