import { useState } from "react";

export type ShowToggle = {
  readonly isShown: boolean;
  hide: () => void;
  show: () => void;
};
export function useShowToggle() {
  const [shown, setShown] = useState(false);
  return {
    isShown: shown,
    hide: () => setShown(false),
    show: () => setShown(true),
  };
}
