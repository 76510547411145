import React from "react";
import { useStore } from "@nanostores/react";
import { Layout } from "../components/layout.js";
import { $resolvers } from "../backbone/resolvers-store.js";
import type { FullResolverSchema } from "@spaceblanc/http-schema";
import * as styles from "./domains-index.module.css";
import { AddButton } from "../components/add-button.jsx";
import { openPage } from "@nanostores/router";
import { $router } from "../backbone/router.js";
import { ChainIdBadge } from "../components/chain-id-badge.js";
import { entryKey } from "@spaceblanc/http-schema";

export function DomainsIndex() {
  const resolvers = useStore($resolvers);

  const renderAllDomains = (resolvers: Record<string, FullResolverSchema>) => {
    return Object.entries(resolvers).map(([key, r]) => {
      const handleClick = () => {
        openPage($router, "domainsEdit", { chainId: String(r.chainId), name: r.domain });
      };

      const renderDescription = () => {
        const descriptionEntry = r.entries[entryKey({ kind: "text", key: "description" })];
        if (descriptionEntry) {
          return <p>{descriptionEntry.value}</p>;
        } else {
          return <></>;
        }
      };

      return (
        <div key={key} className={styles.domainElement} onClick={handleClick}>
          <div className={styles.domainElementTitleRow}>
            <div className={styles.domainElementTitleName}>{r.domain}</div>
            <div>
              <ChainIdBadge chainId={r.chainId} />
            </div>
          </div>
          {renderDescription()}
        </div>
      );
    });
  };

  const render = () => {
    if (!resolvers) {
      return <p>Loading...</p>;
    }
    if (Object.entries(resolvers).length > 0) {
      return <div className={"max-w-screen-sm mx-auto"}>{renderAllDomains(resolvers)}</div>;
    } else {
      return (
        <div className={"text-center my-4"}>
          <p className={"mb-2"}>No domains found</p>
          <AddButton />
        </div>
      );
    }
  };

  return (
    <Layout center={<div className={"center-breadcrumb"}>Domains</div>}>
      <div>{render()}</div>
    </Layout>
  );
}
