import { action, map, onMount } from "nanostores";
import {
  HttpSchema,
  resolverKey,
  type FullResolverSchema,
  type ResolverInputSchema,
  type ResolverUpdateSchema,
  EntrySchema,
  entryKey,
} from "@spaceblanc/http-schema";
import { CLIENT } from "./environment.js";
import { logger } from "@nanostores/logger";

// TODO use deepMap from nanostores
// resolver by name + chainId
export const $resolvers = map<Record<string, FullResolverSchema>>();

onMount($resolvers, () => {
  getAllResolvers().catch((e) => {
    console.error(e);
  });
});

export const getAllResolvers = action($resolvers, "getAllResolvers", async (store) => {
  const response = await CLIENT.resolvers.indexAll();
  switch (response.status) {
    case 200: {
      const body = HttpSchema.resolvers.indexAll.responses["200"].parse(response.body);
      const keys: Record<string, FullResolverSchema> = {};
      for (const r of body.resolvers) {
        keys[resolverKey(r)] = r;
      }
      store.set(keys);
      break;
    }
    default:
      throw new Error(`Can not load resolvers`);
  }
});

export const createResolver = action($resolvers, "createResolver", async (store, input: ResolverInputSchema) => {
  const response = await CLIENT.resolvers.create({
    body: input,
  });
  switch (response.status) {
    case 201: {
      const body = HttpSchema.resolvers.create.responses["201"].parse(response.body);
      store.setKey(resolverKey(body), body);
      break;
    }
    default:
      throw new Error(`Can not load resolvers`);
  }
});

export const updateResolver = action($resolvers, "updateResolver", async (store, resolver: FullResolverSchema) => {
  const response = await CLIENT.resolvers.update({
    params: {
      chainId: String(resolver.chainId),
      domain: resolver.domain,
    },
    body: resolver,
  });
  switch (response.status) {
    case 200: {
      const body = HttpSchema.resolvers.update.responses["200"].parse(response.body);
      store.setKey(resolverKey(body), body);
      break;
    }
    default:
      throw new Error(`Can not load resolvers`);
  }
});

export function addResolverEntry(r: FullResolverSchema, entry: EntrySchema): FullResolverSchema {
  const nextEntries = {
    ...r.entries,
    [entryKey(entry)]: entry,
  };
  return {
    ...r,
    entries: nextEntries,
  };
}

export function deleteResolverEntry(r: FullResolverSchema, kind: EntrySchema["kind"], key: string): FullResolverSchema {
  const entries = Object.values(r.entries);
  const nextEntries = Object.fromEntries(
    entries
      .filter((e) => !(e.key === key && e.kind === kind))
      .map((e) => {
        return [entryKey(e), e];
      }),
  );
  return {
    ...r,
    entries: nextEntries,
  };
}

logger({
  Resolvers: $resolvers,
});
