import React from "react";
import * as styles from "./layout.module.css";
import { useStore } from "@nanostores/react";
import { $resolvers } from "../backbone/resolvers-store";
import { AddButton } from "./add-button.jsx";

type LayoutProps = {
  center?: React.ReactElement;
};

export function Layout(props: React.PropsWithChildren<LayoutProps>) {
  const resolvers = useStore($resolvers);

  const renderRight = () => {
    if (Object.entries(resolvers).length > 0) {
      return (
        <>
          <AddButton />
        </>
      );
    } else {
      return <></>;
    }
  };

  return (
    <div>
      <header className={styles.header}>
        <div className={styles.inner}>
          <div>
            <a href={"/"} target="_self">
              <span className={styles.dashstar}>
                _.<span className={styles.asterisk}>*</span>
              </span>{" "}
              <span>Spaceblanc</span>
            </a>
          </div>
          <div>{props.center}</div>
          <div>{renderRight()}</div>
        </div>
      </header>
      {props.children}
    </div>
  );
}
