import React from "react";
import * as styles from "./input-buttons.module.css";

export function InputButtons<T>(props: {
  original: T;
  current: T;
  onSave?: () => void;
  onUndo?: () => void;
  onDelete?: () => void;
}) {
  const original = JSON.stringify(props.original);
  const current = JSON.stringify(props.current);
  const isDifferent = current !== original;

  const renderSaveButton = () => {
    const canSave = Boolean(current);
    if (canSave) {
      return (
        <div className={styles.inputButton} title={"Save"} onClick={() => props.onSave?.()}>
          ▼
        </div>
      );
    } else {
      return (
        <div className={styles.canNotSaveButton} title={"Can not save an invalid value"}>
          ⊘
        </div>
      );
    }
  };

  if (isDifferent) {
    return (
      <>
        <div className={styles.inputButton} title={"Undo changes"} onClick={() => props.onUndo?.()}>
          ⌫
        </div>
        {renderSaveButton()}
      </>
    );
  } else {
    return (
      <div className={styles.inputButton} title={"Delete"} onClick={() => props.onDelete?.()}>
        ×
      </div>
    );
  }
}
