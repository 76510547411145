.domainElement {
  @apply border rounded my-4 p-4 mx-4 cursor-pointer;
}

.domainElementTitleRow {
  @apply flex;
}

.domainElementTitleName {
  @apply text-xl font-semibold;
  @apply flex-auto;
}
