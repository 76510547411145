import React, { type FormEvent, useState } from "react";
import { Layout } from "../components/layout.js";
import { getPagePath, openPage } from "@nanostores/router";
import { $router } from "../backbone/router.js";
import { createResolver } from "../backbone/resolvers-store.js";
import * as styles from "./domains-new.module.css";

function NewDomainForm() {
  const [name, setName] = useState("");
  const [chainId, setChainId] = useState(1);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    createResolver({
      domain: name,
      chainId: chainId,
      ttl: 300,
    })
      .then(() => {
        openPage($router, "domainsIndex");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <form onSubmit={handleSubmit} className={"border rounded my-4 p-4 mx-4"}>
      <label className={styles.inputRow}>
        <span className={"w-24 inline-block"}>Name</span>
        <input type={"text"} className={styles.inputText} value={name} onChange={(e) => setName(e.target.value)} />
      </label>
      <label className={styles.inputRow}>
        <span className={"w-24 inline-block"}>Chain</span>
        <select
          className={styles.inputText}
          defaultValue={chainId}
          onChange={(e) => {
            setChainId(Number(e.currentTarget.value));
          }}
        >
          <option value="1">Mainnet</option>
          <option value="5">Goerli Testnet</option>
        </select>
      </label>
      <button type={"submit"}>Create</button>
    </form>
  );
}

export function DomainsNew() {
  return (
    <Layout
      center={
        <div>
          <a href={getPagePath($router, "domainsIndex")} className={"center-breadcrumb"}>
            Domains
          </a>{" "}
          <span className={"mx-2 text-neutral-300"}>∕</span> <span className={"center-breadcrumb"}>New</span>
        </div>
      }
    >
      <div className={"max-w-screen-sm mx-auto"}>
        <NewDomainForm />
      </div>
    </Layout>
  );
}
