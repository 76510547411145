import React from "react";
import { useStore } from "@nanostores/react";
import { redirectPage } from "@nanostores/router";
import { $router } from "./backbone/router.js";
import { DomainsIndex } from "./pages/domains-index.js";
import { DomainsNew } from "./pages/domains-new.js";
import { DomainsEdit } from "./pages/domains-edit";
import { numberFromString } from "@spaceblanc/http-schema/codecs";
import { validate, isRight } from "codeco";

export function App() {
  const page = useStore($router);

  switch (page?.route) {
    case "home":
      return redirectPage($router, "domainsIndex");
    case "domainsIndex":
      return <DomainsIndex />;
    case "domainsNew":
      return <DomainsNew />;
    case "domainsEdit":
      const chainId = validate(numberFromString, page.params.chainId);
      if (isRight(chainId)) {
        return <DomainsEdit chainId={chainId.right} domain={page.params.name} />;
      }
    default: // FIXME add exhaustive routing
      return <div>404 Not Found</div>;
  }
}
