.form {
  @apply border rounded my-4 p-4 mx-4;
}

.headerTitle {
  @apply flex;
}

.nameHeader {
  @apply text-2xl font-semibold mb-2;
  font-family: "Victor Mono", monospace;
  @apply flex-auto;
}

.avatar {
  @apply bg-gray-300 rounded-full w-20 h-20 flex border-b-gray-950;
}

.avatarPointer {
  @apply avatar;
  @apply cursor-pointer;
}

.avatarImageWrap {
  @apply h-20 w-20;
}

.avatarImage {
  @apply rounded-full w-20 h-20 overflow-hidden z-10;
}

.avatarEditButton {
  @apply z-20 bg-blend-color-dodge bg-gray-200 opacity-80 rounded-full w-7 h-7 relative ml-14 flex justify-center align-baseline;
  margin-top: -1.75rem;
  line-height: 1.25rem;
  @apply cursor-pointer;
  @apply font-thin;
}

.avatarDeleteButton {
  @apply avatarEditButton;
  margin-top: -3.5rem;
  z-index: 1000;
}

.avatarPlaceholderInnerCircle {
  @apply bg-none border-white border border-dashed rounded-full w-5/6 h-5/6 m-auto flex;
}

.avatarPlaceholderInner {
  @apply m-auto font-thin opacity-20;
}

.inputRow {
  @apply block mb-2;
}

.inputText {
  @apply rounded px-2 py-1 w-full border-neutral-200;
}

.kindHeader {
  @apply text-xl font-semibold flex flex-row items-center;
}

.addButton {
  /*@apply pointer-events-auto text-[0.8125rem] font-semibold;*/
  /*@apply bg-blue-600 text-white hover:bg-blue-500;*/
  /*@apply px-1 rounded-full inline-block;*/
  @apply inline-flex items-center rounded-md px-2 py-0 text-xs font-medium  ring-1 ring-inset;
  @apply h-5 ml-2;
  @apply bg-green-50 text-green-700 ring-green-600/20;
  @apply bg-blue-600 text-white hover:bg-blue-500;
  @apply cursor-pointer;
}

.selectInput {
  @apply w-full border-none shadow-inner;
}

.newEntryContainer {
  @apply mb-4;
}

.newEntryInputLine {
  @apply rounded bg-neutral-100 flex justify-between w-full overflow-hidden border-b border-b-gray-200 shadow-inner mb-1;
}
