import mergeOptions from "merge-options";
import { type, string, number, defaults, boolean, decode, type TypeOf } from "codeco";

const PinFileToIPFSResponse = type(
  {
    IpfsHash: string,
    PinSize: number,
    Timestamp: string, // ISO timestamp really
    isDuplicate: defaults(boolean, false),
  },
  "pinFileToIPFS-response",
);
export type PinFileToIPFSResponse = TypeOf<typeof PinFileToIPFSResponse>;

export type UploadOptions = {
  cidVersion: 0 | 1;
};

const DEFAULT_UPLOAD_OPTIONS = {
  cidVersion: 1,
};

export class PinataClient {
  constructor(
    readonly jwt: string,
    readonly gateway: URL,
  ) {}

  async upload(file: File, options: Partial<UploadOptions> = {}): Promise<PinFileToIPFSResponse> {
    const effectiveOptions = mergeOptions(DEFAULT_UPLOAD_OPTIONS, options);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("pinataOptions", JSON.stringify(effectiveOptions));
    const response = await fetch("https://api.pinata.cloud/pinning/pinFileToIPFS", {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${this.jwt}`,
      },
    });
    const json = await response.json();
    return decode(PinFileToIPFSResponse, json);
  }

  urlForCID(cid: string) {
    return new URL(`/ipfs/${cid}`, this.gateway);
  }
}
